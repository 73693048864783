import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_API } from 'config/config';

export const storeAdminInfo = createAsyncThunk('get/storeadmininfo', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/admin/getadmininfo`, {
      headers: {
        Authorization: `Bearer ${localStorage.TESLA_Token}`
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllUserData = createAsyncThunk('get/getalluserdata', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/user/getalluserdata`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllAmenityData = createAsyncThunk('get/getallamenities', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/amenities/getallamenities`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllFeedbackData = createAsyncThunk('get/get-testimonial', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/testimonials`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllAmenityNames = createAsyncThunk('get/getallamenitienames', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/amenities/getallamenitienames`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllAreaData = createAsyncThunk('get/get', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/area/get`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllDeveloperData = createAsyncThunk('get/getdeveloper', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/developer/getdeveloper`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAboutData = createAsyncThunk('get/getAboutData', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/about/getalluserdata`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllBlogData = createAsyncThunk('get/getAllBlogData', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/blog/getAllBlogData`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllApartmentData = createAsyncThunk('get/getapartment', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/apartment/getapartment`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllPaymentData = createAsyncThunk('get/getpayment', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/payment/getpayment`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllTeamData = createAsyncThunk('get/getallteamdata', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/team/getallteamdata`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getAllIplogData = createAsyncThunk('get/getalliplogdata', async () => {
  try {
    const response = await axios.get(`${BACKEND_API}/api/admin/getalliplogdata`, {
      headers: {
        Authorization: localStorage.TESLA_Token
      }
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const storeSearchKeyword = createAsyncThunk('get/storekeyword', async (keyword) => {
  try {
    return keyword;
  } catch (error) {
    return error.response.data;
  }
});
