import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import PrivateRoute from 'ui-component/PrivateRoute';

const User = Loadable(lazy(() => import('views/user')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Team = Loadable(lazy(() => import('views/team')));
const Apartment = Loadable(lazy(() => import('views/apartment')));
const About = Loadable(lazy(() => import('views/about')));
const Amenities = Loadable(lazy(() => import('views/amenities')));
const Areas = Loadable(lazy(() => import('views/area')));
const Developer = Loadable(lazy(() => import('views/developer')));
const Blog = Loadable(lazy(() => import('views/blog')));
const Payment = Loadable(lazy(() => import('views/payment')));
const Testimonial = Loadable(lazy(() => import('views/testimonial')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <PrivateRoute component={Dashboard} />
    },
    {
      path: '/dashboard',
      element: <PrivateRoute component={Dashboard} />
    },
    {
      path: '/user',
      element: <PrivateRoute component={User} />
    },
    {
      path: '/team',
      element: <PrivateRoute component={Team} />
    },
    {
      path: '/property',
      element: <PrivateRoute component={Apartment} />
    },
    {
      path: '/about',
      element: <PrivateRoute component={About} />
    },
    {
      path: '/amenities',
      element: <PrivateRoute component={Amenities} />
    },
    {
      path: '/area',
      element: <PrivateRoute component={Areas} />
    },
    {
      path: '/developer',
      element: <PrivateRoute component={Developer} />
    },
    {
      path: '/blog',
      element: <PrivateRoute component={Blog} />
    },
    {
      path: '/payment',
      element: <PrivateRoute component={Payment} />
    },
    {
      path: '/testimonial',
      element: <PrivateRoute component={Testimonial} />
    }
  ]
};

export default MainRoutes;
