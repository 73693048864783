import React from 'react';
import { Navigate } from 'react-router-dom';

const PubilcRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.TESLA_Token;

  if (!isAuthenticated) {
    return <Component {...rest} />;
  }

  return <Navigate to="/" />;
};

export default PubilcRoute;
