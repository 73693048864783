import { createSlice } from '@reduxjs/toolkit';
import {
  storeAdminInfo,
  getAllUserData,
  getAllTeamData,
  getAboutData,
  getAllAmenityData,
  getAllAreaData,
  getAllDeveloperData,
  getAllBlogData,
  getAllAmenityNames,
  getAllApartmentData,
  getAllPaymentData,
  getAllFeedbackData
} from './actions';

const PREFIX = 'psif';

const initialState = {
  adminInfo: {},
  isAuthenticated: false,
  allUserData: {},
  allDeveloperData: {},
  allAmenityData: {},
  allAmenityName: [],
  allFeedbackData: {},
  allAreaData: {},
  allDevelopersData: {},
  allBlogData: {},
  allApartmentData: {},
  allPaymentData: {},
  allAboutData: ''
};

export const psifSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(storeAdminInfo.fulfilled.type, (state, action) => {
      state.adminInfo = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(getAllUserData.fulfilled.type, (state, action) => {
      state.allUserData = action.payload;
    });
    builder.addCase(getAllTeamData.fulfilled.type, (state, action) => {
      state.allDeveloperData = action.payload;
    });
    builder.addCase(getAboutData.fulfilled.type, (state, action) => {
      state.allAboutData = action.payload;
    });
    builder.addCase(getAllAreaData.fulfilled.type, (state, action) => {
      state.allAreaData = action.payload;
    });
    builder.addCase(getAllAmenityNames.fulfilled.type, (state, action) => {
      state.allAmenityName = action.payload;
    });
    builder.addCase(getAllFeedbackData.fulfilled.type, (state, action) => {
      state.allFeedbackData = action.payload;
    });
    builder.addCase(getAllBlogData.fulfilled.type, (state, action) => {
      state.allBlogData = action.payload;
    });
    builder.addCase(getAllDeveloperData.fulfilled.type, (state, action) => {
      state.allDevelopersData = action.payload;
    });
    builder.addCase(getAllApartmentData.fulfilled.type, (state, action) => {
      state.allApartmentData = action.payload;
    });
    builder.addCase(getAllPaymentData.fulfilled.type, (state, action) => {
      state.allPaymentData = action.payload;
    });
    builder.addCase(getAllAmenityData.fulfilled.type, (state, action) => {
      state.allAmenityData = action.payload;
    });
  }
});

export {
  storeAdminInfo,
  getAllUserData,
  getAllTeamData,
  getAboutData,
  getAllAmenityData,
  getAllAreaData,
  getAllDeveloperData,
  getAllBlogData,
  getAllAmenityNames,
  getAllApartmentData,
  getAllPaymentData,
  getAllFeedbackData
};

export default psifSlice.reducer;
