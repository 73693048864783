import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Dashboard from '@mui/icons-material/Dashboard';

// Get the value of is_admin from localStorage
const isAdmin = localStorage.getItem('user');
console.log("local storage item below")
console.log(isAdmin)

// constant
const other = {
  id: 'admin',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: Dashboard,
      breadcrumbs: false
    },
    {
      id: 'about',
      title: 'About Us',
      type: 'item',
      url: '/about',
      icon: InfoOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'property',
      title: 'Properties',
      type: 'item',
      url: '/property',
      icon: ApartmentOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'area',
      title: 'Areas',
      type: 'item',
      url: '/area',
      icon: LanguageOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'developer',
      title: 'Developers',
      type: 'item',
      url: '/developer',
      icon: BadgeOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'team',
      title: 'Team',
      type: 'item',
      url: '/team',
      icon: Diversity3OutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'blog',
      title: 'Blog',
      type: 'item',
      url: '/blog',
      icon: BookOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'amenities',
      title: 'Amenities',
      type: 'item',
      url: '/amenities',
      icon: AccountBalanceOutlinedIcon,
      breadcrumbs: false
    },
      // {
    //   id: 'payment',
    //   title: 'Payment Plan',
    //   type: 'item',
    //   url: '/payment',
    //   icon: PaidOutlinedIcon,
    //   breadcrumbs: false
    // },
    {
      id: 'feedback',
      title: 'Testimonials',
      type: 'item',
      url: '/testimonial',
      icon: FeedbackIcon,
      breadcrumbs: false
    }
  ]
};

// Conditionally add "User Management" link if isAdmin is true
if (isAdmin === '1') {
  other.children.splice(1, 0, {
    id: 'user',
    title: 'User Management',
    type: 'item',
    url: '/user',
    icon: PeopleOutlinedIcon,
    breadcrumbs: false
  });
}

export default other;



// import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
// import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
// import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
// import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
// // import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
// import FeedbackIcon from '@mui/icons-material/Feedback';
// import Dashboard from '@mui/icons-material/Dashboard';
// // constant
// const other = {
//   id: 'admin',
//   type: 'group',
//   children: [
//     {
//       id: 'dashboard',
//       title: 'Dashboard',
//       type: 'item',
//       url: '/dashboard',
//       icon: Dashboard,
//       breadcrumbs: false
//     },
//     {
//       id: 'user',
//       title: 'User Management',
//       type: 'item',
//       url: '/user',
//       icon: PeopleOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'about',
//       title: 'About Us',
//       type: 'item',
//       url: '/about',
//       icon: InfoOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'property',
//       title: 'Properties',
//       type: 'item',
//       url: '/property',
//       icon: ApartmentOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'area',
//       title: 'Areas',
//       type: 'item',
//       url: '/area',
//       icon: LanguageOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'developer',
//       title: 'Developers',
//       type: 'item',
//       url: '/developer',
//       icon: BadgeOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'team',
//       title: 'Team',
//       type: 'item',
//       url: '/team',
//       icon: Diversity3OutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'blog',
//       title: 'Blog',
//       type: 'item',
//       url: '/blog',
//       icon: BookOutlinedIcon,
//       breadcrumbs: false
//     },
//     {
//       id: 'amenities',
//       title: 'Amenities',
//       type: 'item',
//       url: '/amenities',
//       icon: AccountBalanceOutlinedIcon,
//       breadcrumbs: false
//     },
//     // {
//     //   id: 'payment',
//     //   title: 'Payment Plan',
//     //   type: 'item',
//     //   url: '/payment',
//     //   icon: PaidOutlinedIcon,
//     //   breadcrumbs: false
//     // },
//     {
//       id: 'feedback',
//       title: 'Testimonials',
//       type: 'item',
//       url: '/testimonial',
//       icon: FeedbackIcon,
//       breadcrumbs: false
//     }
//   ]
// };

// export default other;
