import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component }) => {
  const isAuthenticated = localStorage.TESLA_Token;
  const adminInfo = useSelector((state) => state.psif);
  if (isAuthenticated && adminInfo) {
    return <Component />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
