import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import jwt_decode from 'jwt-decode';

// redux
import { store } from 'store/store';
import { Provider } from 'react-redux';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ==============================|| APP ||============================== //



const App = () => {
  const theme = {
    borderRadius: 12,
    defaultId: 'default',
    fontFamily: "'Roboto', sans-serif",
    isOpen: [],
    opened: true
  };

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(theme)}>
          <Routes />
          <ToastContainer />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
